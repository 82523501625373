import React from 'react';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Card, CardMedia, Subhead, Text } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../data/snippets/card.example');
const snippet2 = require('raw-loader!../../../../data/snippets/card-media.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Card: Card, Subhead: Subhead, Text: Text }}
        code={snippet}
        platform="react"
        gitHubLink="card"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="header" types={['node']}>
            <Text>
              Shown at the top of the card. Can be any combination of elements,
              but note that spacing is taken care of for you.
            </Text>
          </PropListItem>

          <PropListItem name="footer" types={['arrayOf', 'shape']}>
            <Text>
              Can only contain actions the user can take on the card. Each
              action has the following shape:
            </Text>
            <List type="unordered">
              <li>
                <code>label</code> (string) - the text describing the action
              </li>
              <li>
                <code>icon</code> (string) - name of a Uniform icon
              </li>
              <li>
                <code>disabled</code> (bool) - determines whether the action is
                disabled
              </li>
              <li>
                <code>actionRef</code> (func) - a ref to the action
              </li>
              <li>
                <code>qaId</code> (string) - a unique string to control this
                action in automated tests
              </li>
              <li>
                <code>onClick</code> (func) - responds to the action being
                clicked
              </li>
            </List>
          </PropListItem>

          <PropListItem name="media" types={['node']}>
            <Text>
              Shown at the top of the card above the header in full bleed. Can
              be any combination of elements, but note that spacing is taken
              care of for you. If you need any media in the body of your card,
              you can use the CardMedia component to handle the image filling
              properly like so:
            </Text>
            <CodeSnippet
              scope={{
                React: React,
                Card: Card,
                CardMedia: CardMedia,
                Subhead: Subhead,
                Text: Text,
              }}
              code={snippet2}
              platform="react"
            />
          </PropListItem>

          <PropListItem name="onHover" types={['func']}>
            <Text>
              Called when the card is hovered over. Useful for if you have
              something like a SelectMark that you want to show the hover state
              for as well. Note that hover styling is taken care of for you.
            </Text>
          </PropListItem>

          <PropListItem name="onClick" types={['func']}>
            <Text>Called when the card is clicked on.</Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
