import React from 'react';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { Card, CardMedia, Subhead, Text as RNText } from 'hudl-rn-uniform-ui';

const snippet = require('raw-loader!../../../../data/snippets/rn-card.example');
const snippet2 = require('raw-loader!../../../../data/snippets/rn-card-media.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Card: Card, Subhead: Subhead, Text: RNText }}
        code={snippet}
        platform="react-native"
        gitHubLink="card"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="header" types={['node']}>
            <Text>
              Shown at the top of the card. Can be any combination of elements,
              but note that spacing is taken care of for you.
            </Text>
          </PropListItem>

          <PropListItem name="footer" types={['arrayOf', 'shape']}>
            <Text>
              Can only contain actions the user can take on the card. Each
              action has the following shape:
            </Text>
            <List type="unordered">
              <li>
                <code>label</code> (string) - the text describing the action
              </li>
              <li>
                <code>icon</code> (string) - name of a Uniform icon
              </li>
              <li>
                <code>disabled</code> (bool) - determines whether the action is
                disabled
              </li>
              <li>
                <code>onPress</code> (func) - responds to the action being
                pressed
              </li>
            </List>
          </PropListItem>

          <PropListItem name="media" types={['node']}>
            <Text>
              Shown at the top of the card above the header in full bleed. Can
              be any combination of elements, but note that spacing is taken
              care of for you. If you need any media in the body of your card,
              you can use the CardMedia component to handle the image filling
              properly like so:
            </Text>
            <CodeSnippet
              scope={{
                React: React,
                Card: Card,
                CardMedia: CardMedia,
                Subhead: Subhead,
                Text: RNText,
              }}
              code={snippet2}
              platform="react-native"
            />
          </PropListItem>

          <PropListItem name="isFullWidth" types={['boolean']}>
            <Text>Determines whether the card goes edge-to-edge.</Text>
          </PropListItem>

          <PropListItem name="theme" types={['oneOf', 'string']}>
            <Text>Determines the theme of the card.</Text>
            <List type="unordered">
              <li>
                <code>light</code> (default)
              </li>
              <li>
                <code>dark</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this card in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the card.
            </Text>
          </PropListItem>

          <PropListItem name="onPress" types={['func']}>
            <Text>Responds to the card press event.</Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
